import { createI18n } from 'vue-i18n';
import { messages } from '../assets/lang/index.js';

export default function createAppI18n(store) {
  return createI18n({
    legacy: false,
    locale: store.state.locale || 'en',
    fallbackLocale: 'en',
    messages,
    warnHtmlMessage: false,
  });
}
