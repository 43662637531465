<template>
  <v-btn
    component="router-link"
    href="/pricing"
    variant="flat"
    color="primary"
    class="base-button"
    elevation="0"
    @click.stop
    :disabled="disabled">
    {{ t('global.upgrade') }}
    <div class="base-button__pro-text">PRO</div>
  </v-btn>
</template>

<script setup>
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { disabled } = toRefs(props);
</script>

<style lang="scss" scoped>
.base-button {
  display: flex;
  flex-direction: row;
  border-radius: rem(32px);
  text-transform: none;
  text-decoration: none;
  font-weight: 500;
  height: rem(32px);
  gap: 0px;
  max-width: fit-content;
  &__pro-text {
    height: rem(32px);
    display: flex;
    align-items: flex-start;
    margin-top: 4px;
    color: orange;
    font-weight: bold;
    font-size: rem(10px);
  }

  &--disabled {
    pointer-events: auto;
    cursor: default;
  }

  &.v-btn--disabled {
    opacity: 0.5;
    filter: grayscale(1);
  }
}
</style>
