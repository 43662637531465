<template>
  <v-navigation-drawer
    fixed
    temporary
    class="mobile-sidebar bg-bg text-text"
    width="269"
    elevation="0">
    <div class="mobile-sidebar__header">
      <MobileLogo class="mobile-sidebar__logo" />
      <span v-if="isPro" style="color: grey; margin-bottom: 10px">Pro</span>
    </div>
    <div class="mobile-sidebar__content">
      <div class="mobile-sidebar__content-top">
        <NavList class="mobile-sidebar__menu" :sections="sections" @navigate="emit('navigate')" />
      </div>
      <div class="mobile-sidebar__content-bottom">
        <div class="mobile-sidebar__policy-pages">
          <v-btn
            v-for="link in policyPages"
            :key="link.text"
            class="policy-page"
            variant="text"
            text
            density="compact"
            @click="navigate(link)">
            {{ link.text }}
          </v-btn>
        </div>
        <div class="mobile-sidebar__social-media">
          <IconLink
            v-for="link in socialLinks"
            :key="link.name"
            :icon="link.icon"
            :name="link.name"
            :link="link.route"
            @click="onSocialLinkClicked(link)" />
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import NavList from './NavList.vue';
import { socialLinks } from '@/utils/social';
import usePolicyPages, { useNavigate } from '@/composables/policyPages';

import IconLink from '@/components/IconLink.vue';
import MobileLogo from '@/components/MobileLogo.vue';
import SettingsIcon from '@/assets/icons/modules/settings.svg';
import NewChatIcon from '@/assets/icons/modules/new-chat.svg';
import BusinessIcon from '@/assets/icons/modules/domain.svg';
import UsersIcon from '@/assets/icons/modules/users.svg';
// import ChatIcon from '@/assets/icons/source.svg'; // TODO
import ChatHistoryIcon from '@/assets/icons/modules/chat-history.svg';
import SearchIcon from '@/assets/icons/modules/search.svg';
import PricingIcon from '@/assets/icons/modules/pricing.svg';
// import LibraryIcon from '@/assets/icons/modules/library.svg'; // TODO
import AboutIcon from '@/assets/icons/modules/about.svg';
import BlogIcon from '@/assets/icons/modules/blog.svg';
import AccountIcon from '@/assets/icons/modules/account.svg';
import LoginIcon from '@/assets/icons/modules/login.svg';
import LogoutIcon from '@/assets/icons/modules/logout.svg';

import { getRouteName } from '@/utils';

const emit = defineEmits(['navigate']);

const store = useStore();
const { t } = useI18n();
const route = useRoute();
const isSuperAdmin = computed(() => store.getters.isSuperAdmin);
const isOrganizationAdmin = computed(() => store.getters.isOrganizationAdmin);

const activeOrganizationId = computed(() => store.state.organization?.id);
const userProfile = computed(() => store.state.userProfile);
const isNewChat = computed(() => store.getters.isNewChat);
const isPro = computed(() => store.getters.isPro);
const isPayingUser = computed(() => store.getters.isPaying || store.getters.isSuperAdmin);
const hidePricing = computed(
  () => store.getters.isSandbox || store.state.organization || isPayingUser || isSuperAdmin
);

const defaultTabs = computed(() => {
  const tabs = [
    {
      label: `+ ${t('chat.newChatButton')}`,
      to: {
        name: 'Chat',
      },
      exact: true,
      icon: NewChatIcon,
      class: 'text-bg',
      color: 'primary',
      button: true,
      props: () => ({ active: false }),
      listeners: {
        click: () => {
          store.dispatch('newChat');
          store.dispatch('trackEvent', {
            event: 'ChatNew',
            values: {
              user_initiated: true,
            },
          });
          store.commit('SET_STATE_PROPERTY', {
            property: 'chatMobileSection',
            value: 'chat',
          });
          store.commit('SET_STATE_PROPERTY', {
            property: 'mobileSideMenu',
            value: false,
          });
        },
      },
      hide: isNewChat.value && route.name === 'Chat',
    },
    {
      label: t('global.chatHistory'),
      to: {
        name: 'Chat',
      },
      icon: ChatHistoryIcon,
      props: () => {
        if (store.state.chatMobileSection !== 'history') return { active: false };
        return {};
      },
      listeners: {
        click: () => {
          store.commit('SET_STATE_PROPERTY', {
            property: 'chatMobileSection',
            value: 'history',
          });
        },
      },
      hide: !userProfile.value?.uid,
    },
    {
      label: t('global.search'),
      to: {
        name: 'Search',
      },
      icon: SearchIcon,
    },
    // TODO
    // {
    //   label: t('global.library'),
    //   to: '/library',
    //   icon: LibraryIcon,
    // },
    {
      label: t('global.pricing'),
      to: {
        name: 'Pricing',
      },
      icon: PricingIcon,
    },
    {
      label: t('global.about'),
      to: {
        name: 'About',
      },
      icon: AboutIcon,
    },
    {
      label: t('global.knowledgeBase'),
      to: {
        name: 'Knowledge Base',
      },
      icon: BlogIcon,
    },
  ];

  if (hidePricing.value) {
    return tabs.filter((tab) => tab.label !== t('global.pricing'));
  }

  return tabs;
});

const organizationalTabs = computed(() =>
  (isSuperAdmin.value || isOrganizationAdmin.value) && activeOrganizationId.value
    ? [
        {
          label: t('organizationSettings.title'),
          to: { name: 'OrganizationSettings', params: { id: activeOrganizationId.value } },
          icon: SettingsIcon,
        },
      ]
    : []
);

const userTabs = computed(() => [
  {
    label: t('myProfile.title'),
    to: {
      name: 'UserProfile',
    },
    icon: AccountIcon,
    hide: !userProfile.value?.uid,
  },
  {
    label: t('global.login'),
    to: {
      name: 'Login',
    },
    icon: LoginIcon,
    hide: !!userProfile.value?.uid,
  },
  {
    label: t('global.logout'),
    to: {
      name: 'Logout',
    },
    icon: LogoutIcon,
    hide: !userProfile.value?.uid,
    class: 'mobile-sidebar__tab--logout',
  },
]);

const sections = computed(() => {
  const defaults = { title: '', tabs: defaultTabs.value };
  const users = { title: '', tabs: userTabs.value };

  const tabs = [defaults];
  if (organizationalTabs.value.length) {
    const organizationals = { title: t('userMenu.organizational'), tabs: organizationalTabs.value };
    tabs.push(organizationals);
  }
  if (isSuperAdmin.value) {
    const superAdminTabs = [
      {
        label: t('global.organizations'),
        to: {
          name: 'Organizations',
        },
        icon: BusinessIcon,
      },
      {
        label: t('global.users'),
        to: {
          name: 'Users',
        },
        icon: UsersIcon,
      },
    ];
    const superAdmins = { title: t('userMenu.superAdmin'), tabs: superAdminTabs };
    tabs.push(superAdmins);
  }
  tabs.push(users);
  return tabs;
});

const onSocialLinkClicked = (link) => {
  const routeName = getRouteName(route, true);
  store.dispatch('trackEvent', {
    event: 'SocialMediaIconClicked',
    values: {
      page: routeName,
      content: link.id,
      location: 'menu',
    },
  });
};

const policyPages = usePolicyPages();
const navigate = useNavigate();
</script>

<style lang="scss" scoped>
.mobile-sidebar {
  z-index: 1014 !important;
  top: 0 !important;
  bottom: 0 !important;
  height: 100% !important;
  padding: 0 0 rem(16px);
  :deep(+ .v-navigation-drawer__scrim) {
    z-index: 1013 !important;
  }
  :deep() {
    .v-navigation-drawer__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  &__header {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: rem(16px) rem(24px);
    border-bottom: $border;
  }
  &__content {
    padding: rem(24px) rem(16px) 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: rem(24px);
  }
  &__content-bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(24px);
  }
  &__social-media {
    display: flex;
    gap: rem(16px);
  }
  &__policy-pages {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(16px);
    .policy-page {
      @include text12;
      height: rem(16px);
      text-transform: none;
    }
  }
  .policy-page {
    text-transform: none;
  }
}
</style>
