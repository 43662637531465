const EXAMPLE_QUESTIONS = {
  en: {
    title: 'Example questions:',
    list: [
      'Can mindfulness prevent burnout?',
      'How to assess stroke risk in atrial fibrillation?',
      'Are statins cost-effective in the treatment of cardiovascular diseases?',
    ],
  },
  nl: {
    title: 'Voorbeeldvragen:',
    list: [
      'Kan mindfulness burnout voorkomen?',
      'Hoe beoordeel je het risico op een beroerte bij boezemfibrilleren?',
      'Zijn statines bij hart- en vaatziekten kosteneffectief?',
    ],
  },
};

const CHAT_RESPONSE_LANGUAGE_BY_DEFAULT = 'en';

export { EXAMPLE_QUESTIONS, CHAT_RESPONSE_LANGUAGE_BY_DEFAULT };
