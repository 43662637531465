<template>
  <div class="mobile-page-wrapper">
    <MobileHeader
      :no-prepend="noHeaderPrepend"
      :no-append="noHeaderAppend"
      :back="back"
      :back-callback="backCallback">
      <template v-if="hasSlot('header-prepend')" #prepend>
        <slot name="header-prepend" />
      </template>
      <template v-if="hasSlot('header-content')" #default>
        <slot name="header-content" />
      </template>
      <template v-if="hasSlot('header-append')" #append>
        <slot name="header-append" />
      </template>
    </MobileHeader>
    <v-slide-x-transition appear>
      <v-main
        class="main-mobile text-text"
        :class="{
          'bg-bg': !dark,
          'bg-bg-darken-1': dark,
        }"
        transition="scroll-x-reverse-transition">
        <slot name="prepend" />
        <div
          class="main-mobile__content"
          :class="{
            'main-mobile__content--no-padding-x': noPaddingX,
            'main-mobile__content--no-padding-y': noPaddingY,
          }">
          <div class="main-mobile__content-top">
            <slot />
          </div>
          <div v-if="hasSlot('bottom')" class="main-mobile__content-bottom">
            <slot name="bottom" />
          </div>
        </div>
        <slot name="append" />
      </v-main>
    </v-slide-x-transition>
  </div>
</template>

<script setup>
import MobileHeader from '@/components/layout/MobileHeader.vue';
import { hasSlot, useCustomHeader } from '@/composables';

useCustomHeader();

defineProps({
  title: {
    type: String,
    default: '',
  },
  noPaddingX: {
    type: Boolean,
    default: false,
  },
  noPaddingY: {
    type: Boolean,
    default: false,
  },
  noHeaderPrepend: {
    type: Boolean,
    default: false,
  },
  noHeaderAppend: {
    type: Boolean,
    default: false,
  },
  dark: {
    type: Boolean,
    default: false,
  },
  back: {
    type: [String, Object],
    default: undefined,
  },
  backCallback: {
    type: Function,
    default: undefined,
  },
});
</script>

<style lang="scss" scoped>
.mobile-page-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.main-mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(24px);
    position: relative;
    flex-grow: 1;
    padding: rem(24px) rem(16px);
    overflow-y: auto;
    &--no-padding-x {
      padding-left: 0;
      padding-right: 0;
    }
    &--no-padding-y {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &__content-top {
    overflow-y: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__content-bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
  }
}
</style>
